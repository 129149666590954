/*
DESKTOP FIRST APPROACH:

0-600 phone
0-600 tablet portrait mode
900-1200 tablet landscape mode
[1200-1800] normal style
1800+ big desktop

$breakpoint argument choises
- phone
- tab-port
- tab-land
- big-desktop

ORDER:
1. base + typography
2. general layout + grid
3. page layout
4. components

*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  background-color: black;
  font-size: 62.5%; }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 80%; } }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }

body {
  position: relative;
  box-sizing: border-box; }
  body::-webkit-scrollbar {
    width: 0; }
  @media only screen and (max-width: 75em) {
    body {
      padding: 0;
      overflow: hidden; } }
  @media only screen and (max-width: 56.25em) {
    body {
      overflow: auto; } }

::selection {
  background-color: #1B2B34;
  color: #fff; }

@keyframes animateBlue {
  from {
    fill: url(#blue-gradient); }
  to {
    fill: url(#blue-gradient-invert); } }

@keyframes animateGreen {
  from {
    fill: url(#green-gradient); }
  to {
    fill: url(#green-gradient-invert); } }

@keyframes animateYellow {
  from {
    fill: url(#yellow-gradient); }
  to {
    fill: url(#yellow-gradient-invert); } }

@keyframes animateRed {
  from {
    fill: url(#red-gradient); }
  to {
    fill: url(#red-gradient-invert); } }

@keyframes animateViolet {
  from {
    fill: url(#violet-gradient); }
  to {
    fill: url(#violet-gradient-invert); } }

@keyframes animateOpacity {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 0; }
  75% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes animateStroke {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
    stroke-dashoffset: 50;
    stroke-dasharray: 50; }
  50% {
    fill-opacity: 0.5;
    stroke-opacity: 0.3;
    stroke-dashoffset: 0; }
  100% {
    fill-opacity: 1;
    stroke-opacity: 0.3;
    stroke-dashoffset: 0; } }

@keyframes animateStroke-2 {
  0% {
    fill-opacity: 0;
    stroke-width: 1px;
    stroke-dashoffset: 150;
    stroke-dasharray: 150; }
  100% {
    fill-opacity: 0;
    stroke-dashoffset: 0; } }

@keyframes animatePulsate {
  0% {
    fill-opacity: 1;
    stroke-opacity: 1; }
  50% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5; }
  100% {
    fill-opacity: 1;
    stroke-opacity: 1; } }

@keyframes animateSpin {
  100% {
    transform: rotateX(180deg) rotateY(180deg); } }

@keyframes animateRotateY {
  0% {
    transform: rotateY(0deg); }
  50% {
    transform: rotateY(180deg); }
  100% {
    transform: rotateY(0deg); } }

@keyframes animateRotateZ {
  0% {
    transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(360deg); } }

@keyframes animateRotateY-2 {
  0% {
    transform: rotateY(90deg); }
  50% {
    transform: rotateY(270deg); }
  100% {
    transform: rotateY(90deg); } }

@keyframes animateFly {
  0% {
    transform: translateX(0) translateY(0px) scale(1) rotateY(0); }
  50% {
    transform: translateX(-300px) translateY(-300px) scale(0) rotateY(180deg); }
  51% {
    transform: translateX(-300px) translateY(300px) scale(0) rotateY(180deg); }
  100% {
    transform: translateX(0) translateY(0px) scale(1) rotateY(0deg); } }

@keyframes animateScale {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes animateSpinner {
  0% {
    top: 6px;
    bottom: 6px;
    height: 51px; }
  50%, 100% {
    top: 19px;
    bottom: 19px;
    height: 26px; } }

@keyframes scrollRight {
  0% {
    opacity: 1;
    transform: translateX(-10px); }
  100% {
    opacity: 0;
    transform: translateX(10px); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  background-color: black;
  font-size: 62.5%; }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 80%; } }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }

body {
  position: relative;
  box-sizing: border-box; }
  body::-webkit-scrollbar {
    width: 0; }
  @media only screen and (max-width: 75em) {
    body {
      padding: 0;
      overflow: hidden; } }
  @media only screen and (max-width: 56.25em) {
    body {
      overflow: auto; } }

::selection {
  background-color: #1B2B34;
  color: #fff; }

body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  color: #777; }

@font-face {
  font-family: "pwfont";
  src: url("/img/fonts/radikalwut-bold.woff") format("woff");
  font-weight: bold; }

.translate {
  display: inherit; }

.highlighted_centered-text {
  width: 100%;
  text-align: center;
  text-shadow: 0 0 20px;
  font-size: 2rem; }

.u-center-text {
  text-align: center !important; }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-top-small {
  margin-top: 1.5rem !important; }

.u-margin-top-huge {
  margin-bottom: 10rem !important; }

.u-margin-top-big {
  margin-bottom: 8rem !important; }

.under-construction {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 75em) {
    .under-construction {
      display: none; } }
  @media only screen and (max-height: 37.5rem) {
    .under-construction {
      height: 100vh;
      display: flex; } }
  @media only screen and (max-width: 37.5rem) {
    .under-construction {
      height: 100vh;
      display: flex; } }
  @media only screen and (max-device-width: 600px) {
    .under-construction {
      height: 100vh;
      display: flex; } }

.hover-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 95vw;
  height: 95vh;
  z-index: 1; }
  @media only screen and (max-height: 37.5rem) {
    .hover-overlay {
      display: initial; } }
  @media only screen and (max-width: 37.5rem) {
    .hover-overlay {
      display: initial; } }
  @media only screen and (max-device-width: 600px) {
    .hover-overlay {
      display: initial; } }

.main-container {
  background-image: linear-gradient(0.3turn, rgba(27, 43, 52, 0.2), rgba(54, 56, 63, 0.01), rgba(13, 17, 26, 0.2)), url("/img/card-black.webp");
  height: 100vh;
  display: grid;
  grid-template-columns: min-content minmax(45vw, 1fr);
  grid-template-rows: auto;
  justify-items: center; }
  @media only screen and (max-width: 75em) {
    .main-container {
      grid-template-columns: 80vw 1fr;
      grid-template-rows: 1fr; } }
  @media only screen and (max-width: 56.25em) {
    .main-container {
      grid-template-columns: min-content minmax(45vw, 1fr);
      height: 100vh;
      margin: 0; } }

#st0:hover .st0 {
  animation: 1s animateOpacity forwards 1 ease-out; }

.navigation {
  display: none; }
  @media only screen and (max-width: 75em) {
    .navigation {
      width: 97vw; } }
  @media only screen and (max-width: 56.25em) {
    .navigation {
      display: inline; } }
  @media only screen and (max-height: 37.5rem) {
    .navigation {
      display: none; } }
  @media only screen and (max-width: 37.5rem) {
    .navigation {
      display: none; } }
  @media only screen and (max-device-width: 600px) {
    .navigation {
      display: none; } }
  .navigation__checkbox {
    display: none; }
  .navigation__button {
    background-color: #fff;
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 1.5rem;
    right: 4.5rem;
    border-radius: 50%;
    z-index: 1000;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer; }
    @media only screen and (max-width: 56.25em) {
      .navigation__button {
        top: 5rem;
        right: 5rem; } }
  .navigation__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 2rem;
    right: 5rem;
    background-image: linear-gradient(to right, rgba(27, 43, 52, 0.8), black);
    z-index: 999;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1); }
    @media only screen and (max-width: 56.25em) {
      .navigation__background {
        top: 5rem;
        right: 5rem; } }
  .navigation__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    opacity: 0;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%; }
  .navigation__item {
    margin: 1rem; }
    .navigation__item--me {
      color: #5374a6; }
  .navigation__link:link, .navigation__link:visited {
    display: inline-block;
    cursor: unset;
    padding: 1rem 2rem;
    font-size: 3rem;
    font-weight: 300;
    text-decoration: none;
    text-transform: uppercase;
    background-size: 230%;
    transition: all .4s;
    border: 1px solid;
    border-radius: 10px; }
    .navigation__link:link span, .navigation__link:visited span {
      margin-right: 1.5rem;
      display: inline-block; }
  .navigation__link:hover, .navigation__link:active {
    background-position: 100%;
    transform: translateX(1rem); }
  .navigation__link-label {
    cursor: pointer; }
  .navigation__link--me {
    color: #5374a6;
    border-color: #5374a6;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #5374a6 50%); }
    .navigation__link--me:hover {
      color: #fff;
      border-color: #5374a6; }
  .navigation__link--free-time {
    color: #fac863;
    border-color: #fac863;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fac863 50%); }
    .navigation__link--free-time:hover {
      color: #fff;
      border-color: #fac863; }
  .navigation__link--work {
    color: #87be34;
    border-color: #87be34;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #87be34 50%); }
    .navigation__link--work:hover {
      color: #fff;
      border-color: #87be34; }
  .navigation__link--education {
    color: #ec4949;
    border-color: #ec4949;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #ec4949 50%); }
    .navigation__link--education:hover {
      color: #fff;
      border-color: #ec4949; }
  .navigation__link--personal-projects {
    color: #5500a7;
    border-color: #5500a7;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #5500a7 50%); }
    .navigation__link--personal-projects:hover {
      color: #fff;
      border-color: #5500a7; }
  .navigation__checkbox:checked ~ .navigation__background {
    transform: scale(80); }
  .navigation__checkbox:checked ~ .navigation__nav {
    opacity: 1;
    width: 100%; }
  .navigation__icon {
    position: relative;
    margin-top: 3.5rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 3rem;
      height: 2px;
      background-color: #333;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s; }
    .navigation__icon::before {
      top: -.8rem; }
    .navigation__icon::after {
      top: .8rem; }
  .navigation__button:hover .navigation__icon::before {
    top: -1rem; }
  .navigation__button:hover .navigation__icon::after {
    top: 1rem; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(135deg); }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg); }

.tablet-navigation_container {
  display: none;
  width: 100%;
  justify-content: center; }
  @media only screen and (max-width: 75em) {
    .tablet-navigation_container {
      grid-area: 1 / 2 / 2 / 3;
      display: flex; } }
  @media only screen and (max-width: 56.25em) {
    .tablet-navigation_container {
      display: none; } }

.tablet-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .tablet-navigation .nav-icon {
    max-height: 7rem;
    max-width: 7rem;
    margin-bottom: 2rem; }
  .tablet-navigation__link--me:hover svg {
    fill: #5374a6;
    animation: 2s animatePulsate 1s infinite ease-in-out, 2s animateScale infinite ease-in-out; }
  .tablet-navigation__link--work {
    position: relative; }
    .tablet-navigation__link--work .nav-icon--work-next {
      position: absolute;
      top: 0;
      left: 0; }
  .tablet-navigation__link--work:hover .nav-icon--work {
    fill: #87be34;
    transform-origin: 50% 50%;
    animation: 2s animateRotateY infinite linear; }
  .tablet-navigation__link--work:hover .nav-icon--work-next {
    fill: #87be34;
    transform-origin: 50% 50%;
    animation: 2s animateRotateY-2 infinite linear; }
  .tablet-navigation__link--personal-projects:hover svg {
    fill: transparent;
    stroke: #5500a7;
    stroke-width: 2px;
    stroke-dasharray: 400;
    animation: 1.5s animateStroke-2 infinite alternate, animateScale 2s infinite linear, 2s animateRotateZ infinite linear; }
  .tablet-navigation__link--education:hover svg {
    fill: #ec4949;
    animation: 2s animateRotateY infinite linear; }
  .tablet-navigation__link--free-time:hover svg {
    fill: #aa7900;
    animation: 2s animateFly infinite ease-in-out; }
  .tablet-navigation svg {
    fill: #fff;
    stroke-width: .1px;
    text-decoration: none;
    transition: all 1s ease-out; }

.language-navigation {
  position: absolute;
  top: unset;
  bottom: 30px;
  right: 50px;
  display: flex; }
  @media only screen and (max-height: 37.5rem) {
    .language-navigation {
      z-index: 100;
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex; } }
  @media only screen and (max-width: 37.5rem) {
    .language-navigation {
      z-index: 100;
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex; } }
  @media only screen and (max-device-width: 600px) {
    .language-navigation {
      z-index: 100;
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex; } }

.main-image {
  position: relative;
  grid-area: 1 / 2 / 2 / 3;
  align-self: center;
  margin: 0 2rem;
  width: 90%;
  height: 90%;
  padding: 5% 0;
  text-align: center;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 75em) {
    .main-image {
      display: none; } }

svg#main_logo {
  height: 80%;
  max-height: 90vh;
  max-width: 100%;
  padding: 0 5px; }
  svg#main_logo::after {
    content: "";
    display: table;
    clear: both; }

path, circle {
  cursor: pointer; }

a:active, a:focus, a:hover {
  outline: none; }

#st0 {
  fill: url(#blue-gradient);
  filter: url(#blue-shadow);
  fill-opacity: 0.4;
  box-shadow: 3rem 3rem 5rem rgba(0, 0, 0, 0.8);
  transform-origin: 50% 50%;
  animation: 2s animateBlue forwards infinite cubic-bezier(1, -0.94, 0.49, 1.43); }
  #st0:hover .st0 {
    animation: 2s animateOpacity forwards infinite ease-out; }

#st1 {
  fill: url(#green-gradient);
  filter: url(#green-shadow);
  fill-opacity: 0.4;
  animation: 2.5s animateGreen forwards infinite cubic-bezier(1, -0.94, 0.49, 1.43); }
  #st1:hover .st1 {
    animation: 2s animateOpacity forwards infinite ease-out; }

#st2 {
  fill: url(#violet-gradient);
  filter: url(#violet-shadow);
  fill-opacity: 0.4;
  animation: 2.2s animateViolet forwards infinite cubic-bezier(1, -0.94, 0.49, 1.43);
  cursor: pointer; }
  #st2:hover .st2 {
    animation: 2s animateOpacity forwards infinite ease-out; }

#st3 {
  fill: url(#red-gradient);
  filter: url(#red-shadow);
  transform-origin: 50% 50%;
  fill-opacity: 0.4;
  animation: 2.7s animateRed forwards infinite cubic-bezier(1, -0.94, 0.49, 1.43); }
  #st3:hover .st3 {
    animation: 2s animateOpacity forwards infinite ease-out; }

#st4 {
  fill: url(#yellow-gradient);
  filter: url(#yellow-shadow);
  fill-opacity: 0.4;
  animation: 2.9s animateYellow forwards infinite cubic-bezier(1, -0.94, 0.49, 1.43); }
  #st4:hover .st4 {
    animation: 2s animateOpacity forwards infinite ease-out; }

#st0-icon {
  stroke: #436186;
  fill: #436186;
  filter: url(#blue-shadow);
  stroke-opacity: 0;
  stroke-width: 2px;
  stroke-dasharray: 150;
  stroke-dashoffset: 150; }

#st1-icon {
  fill: #9bbe2e;
  stroke: #a9be2e;
  filter: url(#green-shadow);
  stroke-opacity: 0;
  stroke-width: 1px;
  stroke-dasharray: 150;
  stroke-dashoffset: 150; }

#st2-icon {
  stroke: #47008e;
  fill: #47008e;
  stroke-opacity: 0;
  filter: url(#violet-shadow);
  stroke-width: 3px;
  stroke-dasharray: 150;
  stroke-dashoffset: 150; }

#st3-icon {
  stroke: #ec4949;
  fill: #ec4949;
  filter: url(#red-shadow);
  stroke-opacity: 0;
  stroke-width: 2px;
  stroke-dasharray: 150; }

#st4-icon {
  stroke: #aa7900;
  fill: #aa7900;
  filter: url(#yellow-shadow);
  stroke-opacity: 0;
  stroke-width: 2px;
  stroke-dasharray: 150; }

#st0-icon:hover .st0-icon {
  animation: 3s animateStroke ease-in-out infinite alternate; }

#st1-icon:hover .st1-icon {
  animation: 3s animateStroke ease-in-out infinite alternate; }

#st2-icon:hover .st2-icon {
  animation: 3s animateStroke ease-in-out infinite alternate; }

#st3-icon:hover .st3-icon {
  animation: 3s animateStroke ease-in-out infinite alternate; }

#st4-icon:hover .st4-icon {
  animation: 3s animateStroke ease-in-out infinite alternate; }

.card-container {
  grid-area: 1 / 1 / 2 / 2;
  width: 0;
  display: flex;
  transition: opacity 2s ease-out;
  transform-style: preserve-3d;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  opacity: 0;
  overflow: hidden; }
  .card-container:target {
    width: 100%;
    min-width: 50vw;
    opacity: 1; }
  @media only screen and (max-width: 75em) {
    .card-container:target {
      width: 100%;
      opacity: 1; } }
  @media only screen and (max-width: 56.25em) {
    .card-container {
      display: flex; }
      .card-container:target {
        width: 97vw; }
      .card-container .btn--transparent {
        display: none; } }
  @media only screen and (max-height: 37.5rem) {
    .card-container {
      width: 100vw;
      height: 100vh;
      opacity: 1;
      align-items: self-start; } }
  @media only screen and (max-width: 37.5rem) {
    .card-container {
      width: 100vw;
      height: 100vh;
      opacity: 1;
      align-items: self-start; } }
  @media only screen and (max-device-width: 600px) {
    .card-container {
      width: 100vw;
      height: 100vh;
      opacity: 1;
      align-items: self-start; } }

.card {
  width: 90%;
  height: 90%;
  border-radius: 25px;
  color: #f7f7f7;
  position: relative; }
  .card__side--front .btn-close {
    display: none; }
    @media only screen and (max-width: 75em) {
      .card__side--front .btn-close {
        display: block; } }
    @media only screen and (max-height: 37.5rem) {
      .card__side--front .btn-close {
        display: none; } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--front .btn-close {
        display: none; } }
    @media only screen and (max-device-width: 600px) {
      .card__side--front .btn-close {
        display: none; } }
  .card__side--front:not(.card__no-rotate) .btn-rotate {
    display: block; }
    @media only screen and (max-width: 75em) {
      .card__side--front:not(.card__no-rotate) .btn-rotate {
        display: none; } }
  .card__no-rotate .btn-close {
    display: block; }
    @media only screen and (max-width: 75em) {
      .card__no-rotate .btn-close {
        display: none; } }
  .card:hover .card__side--front:not(.card__no-rotate) {
    transform: rotateY(180deg); }
    @media only screen and (max-width: 75em) {
      .card:hover .card__side--front:not(.card__no-rotate) {
        transform: rotateY(0); } }
  .card:hover .card__side--front:not(.card__no-rotate) .card__icon {
    transform: translateX(-50%) rotateY(360deg);
    margin: 2rem 50% 0 50%; }
    @media only screen and (max-width: 75em) {
      .card:hover .card__side--front:not(.card__no-rotate) .card__icon {
        transform: none;
        margin: 2rem 0 0 2rem; } }
  .card:hover .card__side--front:not(.card__no-rotate) .card__header-text {
    opacity: 0; }
    @media only screen and (max-width: 75em) {
      .card:hover .card__side--front:not(.card__no-rotate) .card__header-text {
        opacity: 1; } }
  @media only screen and (max-height: 37.5rem) {
    .card {
      margin-left: 2rem;
      margin-right: 2rem; }
      .card__side--back .card__header {
        display: none; } }
  @media only screen and (max-width: 37.5rem) {
    .card {
      margin-left: 2rem;
      margin-right: 2rem; }
      .card__side--back .card__header {
        display: none; } }
  @media only screen and (max-device-width: 600px) {
    .card {
      margin-left: 2rem;
      margin-right: 2rem; }
      .card__side--back .card__header {
        display: none; } }
  .card:hover .card__side--back {
    transform: rotateY(0deg); }
  @media only screen and (max-height: 37.5rem) {
    .card {
      width: 100%;
      height: 98%; } }
  @media only screen and (max-width: 37.5rem) {
    .card {
      width: 100%;
      height: 98%; } }
  @media only screen and (max-device-width: 600px) {
    .card {
      width: 100%;
      height: 98%; } }
  .card__side {
    width: 100%;
    height: 100%;
    transition: all 1.5s ease-in-out;
    position: absolute;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    border-radius: 10px; }
    @media only screen and (max-width: 75em) {
      .card__side {
        position: relative;
        height: 100%; } }
    @media only screen and (max-height: 37.5rem) {
      .card__side {
        transition: all 0.5s ease-in-out;
        backface-visibility: hidden; } }
    @media only screen and (max-width: 37.5rem) {
      .card__side {
        transition: all 0.5s ease-in-out;
        backface-visibility: hidden; } }
    @media only screen and (max-device-width: 600px) {
      .card__side {
        transition: all 0.5s ease-in-out;
        backface-visibility: hidden; } }
    @media only screen and (max-width: 75em) {
      .card__side--front {
        position: absolute;
        top: 0;
        transform: rotateY(0); } }
    @media only screen and (max-height: 37.5rem) {
      .card__side--front {
        transform: rotateY(180deg); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--front {
        transform: rotateY(180deg); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--front {
        transform: rotateY(180deg); } }
    @media only screen and (max-width: 75em) {
      .card__side--back.card__side--me, .card__side--back.card__side--work, .card__side--back.card__side--education, .card__side--back.card__side--personal-projects, .card__side--back.card__side--free-time {
        background-image: none;
        box-shadow: none; } }
    .card__side--back {
      transform: rotateY(-180deg); }
      @media only screen and (max-width: 75em) {
        .card__side--back {
          position: absolute;
          top: 35vh;
          height: 50vh;
          transform: rotateY(0); } }
      @media only screen and (max-height: 37.5rem) {
        .card__side--back {
          position: absolute;
          height: 100%;
          top: 0;
          transform: rotateY(180deg); } }
      @media only screen and (max-width: 37.5rem) {
        .card__side--back {
          position: absolute;
          height: 100%;
          top: 0;
          transform: rotateY(180deg); } }
      @media only screen and (max-device-width: 600px) {
        .card__side--back {
          position: absolute;
          height: 100%;
          top: 0;
          transform: rotateY(180deg); } }
    @media only screen and (max-height: 37.5rem) {
      .card__side {
        transition: all 0.5s ease-in-out; } }
    @media only screen and (max-width: 37.5rem) {
      .card__side {
        transition: all 0.5s ease-in-out; } }
    @media only screen and (max-device-width: 600px) {
      .card__side {
        transition: all 0.5s ease-in-out; } }
    .card__side--me {
      box-shadow: 1rem 1rem 1rem rgba(67, 97, 134, 0.3);
      background-image: linear-gradient(175deg, transparent 30%, rgba(83, 116, 166, 0.1) 75%, rgba(83, 116, 166, 0.2)), linear-gradient(100deg, transparent 30%, rgba(83, 116, 166, 0.1) 70%, rgba(91, 129, 182, 0.2)); }
    @media only screen and (max-height: 37.5rem) {
      .card__side--front.card__side--me {
        transform: rotateY(0deg); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--front.card__side--me {
        transform: rotateY(0deg); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--front.card__side--me {
        transform: rotateY(0deg); } }
    @media only screen and (max-height: 37.5rem) {
      .card__side--back.card__side--me {
        box-shadow: 1rem 1rem 1rem rgba(67, 97, 134, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(83, 116, 166, 0.1) 75%, rgba(83, 116, 166, 0.2)), linear-gradient(100deg, transparent 30%, rgba(83, 116, 166, 0.1) 70%, rgba(91, 129, 182, 0.2)); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--back.card__side--me {
        box-shadow: 1rem 1rem 1rem rgba(67, 97, 134, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(83, 116, 166, 0.1) 75%, rgba(83, 116, 166, 0.2)), linear-gradient(100deg, transparent 30%, rgba(83, 116, 166, 0.1) 70%, rgba(91, 129, 182, 0.2)); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--back.card__side--me {
        box-shadow: 1rem 1rem 1rem rgba(67, 97, 134, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(83, 116, 166, 0.1) 75%, rgba(83, 116, 166, 0.2)), linear-gradient(100deg, transparent 30%, rgba(83, 116, 166, 0.1) 70%, rgba(91, 129, 182, 0.2)); } }
    .card__side--work {
      box-shadow: 1rem 1rem 1rem rgba(155, 190, 46, 0.3);
      background-image: linear-gradient(175deg, transparent 30%, rgba(135, 190, 52, 0.1) 75%, rgba(155, 190, 46, 0.2)), linear-gradient(100deg, transparent 30%, rgba(169, 190, 46, 0.1) 70%, rgba(155, 190, 46, 0.2)); }
    @media only screen and (max-height: 37.5rem) {
      .card__side--back.card__side--work {
        box-shadow: 1rem 1rem 1rem rgba(155, 190, 46, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(135, 190, 52, 0.1) 75%, rgba(155, 190, 46, 0.2)), linear-gradient(100deg, transparent 30%, rgba(169, 190, 46, 0.1) 70%, rgba(155, 190, 46, 0.2)); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--back.card__side--work {
        box-shadow: 1rem 1rem 1rem rgba(155, 190, 46, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(135, 190, 52, 0.1) 75%, rgba(155, 190, 46, 0.2)), linear-gradient(100deg, transparent 30%, rgba(169, 190, 46, 0.1) 70%, rgba(155, 190, 46, 0.2)); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--back.card__side--work {
        box-shadow: 1rem 1rem 1rem rgba(155, 190, 46, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(135, 190, 52, 0.1) 75%, rgba(155, 190, 46, 0.2)), linear-gradient(100deg, transparent 30%, rgba(169, 190, 46, 0.1) 70%, rgba(155, 190, 46, 0.2)); } }
    .card__side--education {
      box-shadow: 1rem 1rem 1rem rgba(249, 118, 94, 0.3);
      background-image: linear-gradient(175deg, transparent 30%, rgba(236, 73, 73, 0.1) 75%, rgba(236, 95, 103, 0.1)), linear-gradient(100deg, transparent 30%, rgba(236, 73, 73, 0.1) 70%, rgba(236, 95, 103, 0.1)); }
    @media only screen and (max-height: 37.5rem) {
      .card__side--back.card__side--education {
        box-shadow: 1rem 1rem 1rem rgba(249, 118, 94, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(236, 73, 73, 0.1) 75%, rgba(236, 95, 103, 0.1)), linear-gradient(100deg, transparent 30%, rgba(236, 73, 73, 0.1) 70%, rgba(236, 95, 103, 0.1)); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--back.card__side--education {
        box-shadow: 1rem 1rem 1rem rgba(249, 118, 94, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(236, 73, 73, 0.1) 75%, rgba(236, 95, 103, 0.1)), linear-gradient(100deg, transparent 30%, rgba(236, 73, 73, 0.1) 70%, rgba(236, 95, 103, 0.1)); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--back.card__side--education {
        box-shadow: 1rem 1rem 1rem rgba(249, 118, 94, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(236, 73, 73, 0.1) 75%, rgba(236, 95, 103, 0.1)), linear-gradient(100deg, transparent 30%, rgba(236, 73, 73, 0.1) 70%, rgba(236, 95, 103, 0.1)); } }
    .card__side--personal-projects {
      box-shadow: 1rem 1rem 2rem rgba(85, 0, 167, 0.3);
      background-image: linear-gradient(175deg, transparent 30%, rgba(85, 0, 167, 0.1) 75%, rgba(77, 0, 154, 0.2)), linear-gradient(100deg, transparent 30%, rgba(71, 0, 142, 0.1) 70%, rgba(77, 0, 154, 0.2)); }
    @media only screen and (max-width: 75em) {
      .card__side--personal-projects {
        font-size: 1.5rem; } }
    @media only screen and (max-height: 37.5rem) {
      .card__side--back.card__side--personal-projects {
        box-shadow: 1rem 1rem 2rem rgba(85, 0, 167, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(85, 0, 167, 0.1) 75%, rgba(77, 0, 154, 0.2)), linear-gradient(100deg, transparent 30%, rgba(71, 0, 142, 0.1) 70%, rgba(77, 0, 154, 0.2)); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--back.card__side--personal-projects {
        box-shadow: 1rem 1rem 2rem rgba(85, 0, 167, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(85, 0, 167, 0.1) 75%, rgba(77, 0, 154, 0.2)), linear-gradient(100deg, transparent 30%, rgba(71, 0, 142, 0.1) 70%, rgba(77, 0, 154, 0.2)); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--back.card__side--personal-projects {
        box-shadow: 1rem 1rem 2rem rgba(85, 0, 167, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(85, 0, 167, 0.1) 75%, rgba(77, 0, 154, 0.2)), linear-gradient(100deg, transparent 30%, rgba(71, 0, 142, 0.1) 70%, rgba(77, 0, 154, 0.2)); } }
    .card__side--free-time {
      box-shadow: 1rem 1rem 1rem rgba(170, 121, 0, 0.3);
      background-image: linear-gradient(175deg, transparent 30%, rgba(250, 200, 99, 0.1) 75%, rgba(244, 185, 42, 0.2)), linear-gradient(100deg, transparent 30%, rgba(250, 200, 99, 0.1) 70%, rgba(244, 185, 42, 0.2)); }
    @media only screen and (max-height: 37.5rem) {
      .card__side--back.card__side--free-time {
        box-shadow: 1rem 1rem 1rem rgba(170, 121, 0, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(250, 200, 99, 0.1) 75%, rgba(244, 185, 42, 0.2)), linear-gradient(100deg, transparent 30%, rgba(250, 200, 99, 0.1) 70%, rgba(244, 185, 42, 0.2)); } }
    @media only screen and (max-width: 37.5rem) {
      .card__side--back.card__side--free-time {
        box-shadow: 1rem 1rem 1rem rgba(170, 121, 0, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(250, 200, 99, 0.1) 75%, rgba(244, 185, 42, 0.2)), linear-gradient(100deg, transparent 30%, rgba(250, 200, 99, 0.1) 70%, rgba(244, 185, 42, 0.2)); } }
    @media only screen and (max-device-width: 600px) {
      .card__side--back.card__side--free-time {
        box-shadow: 1rem 1rem 1rem rgba(170, 121, 0, 0.3);
        background-image: linear-gradient(175deg, transparent 30%, rgba(250, 200, 99, 0.1) 75%, rgba(244, 185, 42, 0.2)), linear-gradient(100deg, transparent 30%, rgba(250, 200, 99, 0.1) 70%, rgba(244, 185, 42, 0.2)); } }
  .card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    backface-visibility: visible; }
  .card__header {
    max-width: 100%;
    min-height: 10%;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d; }
    @media only screen and (max-height: 37.5rem) {
      .card__header {
        backface-visibility: hidden;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; } }
    @media only screen and (max-width: 37.5rem) {
      .card__header {
        backface-visibility: hidden;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; } }
    @media only screen and (max-device-width: 600px) {
      .card__header {
        backface-visibility: hidden;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; } }
  .card__icon {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 8rem;
    height: 8rem;
    min-width: 3rem;
    min-height: 8rem;
    fill: #fff;
    transition: all 1.5s ease-in-out; }
    @media only screen and (max-height: 37.5rem) {
      .card__icon {
        margin-top: 0;
        min-width: 10rem;
        width: 10rem;
        margin-left: -3rem; } }
    @media only screen and (max-width: 37.5rem) {
      .card__icon {
        margin-top: 0;
        min-width: 10rem;
        width: 10rem;
        margin-left: -3rem; } }
    @media only screen and (max-device-width: 600px) {
      .card__icon {
        margin-top: 0;
        min-width: 10rem;
        width: 10rem;
        margin-left: -3rem; } }
  .card__header-text {
    text-align: right;
    font-size: 4rem;
    font-weight: 100;
    text-transform: uppercase;
    transform: translateZ(100px);
    transition: all 1.5s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; }
    @media only screen and (max-height: 37.5rem) {
      .card__header-text {
        margin-top: 1rem;
        margin-right: 2rem; } }
    @media only screen and (max-width: 37.5rem) {
      .card__header-text {
        margin-top: 1rem;
        margin-right: 2rem; } }
    @media only screen and (max-device-width: 600px) {
      .card__header-text {
        margin-top: 1rem;
        margin-right: 2rem; } }
  .card__main {
    margin: 2rem 2rem auto;
    text-align: justify;
    color: #fff;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }
    @media only screen and (max-width: 75em) {
      .card__main {
        display: unset;
        height: unset; } }
    @media only screen and (max-height: 37.5rem) {
      .card__main {
        text-align: center;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 37.5rem) {
      .card__main {
        text-align: center;
        font-size: 1.5rem; } }
    @media only screen and (max-device-width: 600px) {
      .card__main {
        text-align: center;
        font-size: 1.5rem; } }
  .card__footer {
    display: flex;
    justify-content: flex-end;
    margin: 3rem;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d; }
  .card__link {
    color: #f7f7f7; }

.btn {
  position: relative;
  transition: all .2s; }
  .btn, .btn:link, .btn:visited {
    text-decoration: none;
    cursor: pointer; }
  .btn:hover {
    transform: translateY(-3px); }
  .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 0 rgba(0, 0, 0, 0.2); }
  .btn--transparent {
    font-size: 1rem;
    padding: 1rem 3rem;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.4);
    color: #fff;
    transition: all .2s; }
  .btn--white {
    font-size: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 100px;
    background-color: transparent;
    color: #fff;
    transition: all .2s;
    z-index: 2000; }
    .btn--white:active {
      color: black;
      background-color: white; }
    .btn--white:not(:last-child) {
      margin-left: 30px; }
    .btn--white::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid gray;
      transition: all .4s; }

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.5);
  opacity: 0; }

@media only screen and (max-width: 75em) {
  .btn-close,
  .btn-rotate {
    display: none; } }

.spinner {
  display: flex;
  align-items: center; }
  .spinner__before-text {
    display: flex;
    align-items: center; }
  .spinner__animation {
    position: relative;
    width: 6rem;
    height: 6rem;
    display: flex; }
    .spinner__animation div {
      display: inline-block;
      position: absolute;
      left: 1rem;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 1rem;
      background: #fff;
      animation: animateSpinner 1s ease-in-out infinite; }
    .spinner__animation div:nth-child(1) {
      left: .6rem;
      animation-delay: -0.25s; }
    .spinner__animation div:nth-child(2) {
      left: 2.3rem;
      animation-delay: -0.12s; }
    .spinner__animation div:nth-child(3) {
      left: 4rem;
      animation-delay: 0s; }
  .spinner__after-text {
    display: flex;
    align-items: center; }

.scroll-right,
.scroll-right:before {
  position: absolute;
  left: 50%; }

.scroll-right {
  width: 50px;
  height: 30px;
  margin-left: -20px;
  bottom: 8%;
  margin-top: -35px;
  border: 1px #fff solid;
  border-radius: 25px;
  opacity: 0; }

.scroll-right.animated {
  animation: animateOpacity 1.5s; }

.scroll-right:before {
  content: '';
  width: 5px;
  height: 5px;
  background: #fff;
  margin-left: -4px;
  top: 12px;
  border-radius: 2px;
  opacity: 0; }

.scroll-right.animated:before {
  animation: scrollRight 1.5s; }

.quote-block {
  position: relative;
  margin-top: 3rem;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .quote-block__text {
    border-top: 1px solid #fff;
    padding: 3rem 3rem;
    font-size: 2rem;
    align-self: center;
    text-shadow: 0 0 20px; }
  .quote-block__author {
    position: absolute;
    bottom: 2rem; }

@media only screen and (max-width: 75em) {
  #work .card__side--back {
    margin-top: 22rem; } }

@media only screen and (max-height: 37.5rem) {
  #work .card__side--back {
    margin-top: 0rem; } }

@media only screen and (max-width: 37.5rem) {
  #work .card__side--back {
    margin-top: 0rem; } }

@media only screen and (max-device-width: 600px) {
  #work .card__side--back {
    margin-top: 0rem; } }

.work-entry {
  padding: 2rem; }
  @media only screen and (max-height: 37.5rem) {
    .work-entry {
      width: 100%; } }
  @media only screen and (max-width: 37.5rem) {
    .work-entry {
      width: 100%; } }
  @media only screen and (max-device-width: 600px) {
    .work-entry {
      width: 100%; } }
  .work-entry:not(:first-of-type) {
    margin-top: 5rem; }
    @media only screen and (max-height: 37.5rem) {
      .work-entry:not(:first-of-type) {
        margin-top: 0; } }
    @media only screen and (max-width: 37.5rem) {
      .work-entry:not(:first-of-type) {
        margin-top: 0; } }
    @media only screen and (max-device-width: 600px) {
      .work-entry:not(:first-of-type) {
        margin-top: 0; } }
  .work-entry__header {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .work-entry__date {
    font-weight: bold; }
  .work-entry__position {
    font-weight: bold; }
  .work-entry__company {
    font-style: italic; }
  .work-entry__content {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    text-align: justify; }

.education-entry {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .education-entry:not(:first-of-type) {
    margin-top: -3rem; }
  @media only screen and (max-height: 37.5rem) {
    .education-entry {
      width: 100%; } }
  @media only screen and (max-width: 37.5rem) {
    .education-entry {
      width: 100%; } }
  @media only screen and (max-device-width: 600px) {
    .education-entry {
      width: 100%; } }
  .education-entry__title {
    margin-top: 5rem; }
  .education-entry__section:not(:first-of-type) {
    width: 100%;
    margin-top: 1.5rem; }
  .education-entry__header {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .education-entry__date {
    font-weight: bold; }
  .education-entry__institution {
    font-weight: bold; }
  .education-entry__company {
    font-style: italic; }
  .education-entry__content {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    text-align: justify; }

.pw-text {
  font-family: pwfont, "Raleway", sans-serif; }

.email-holder {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 6rem;
  font-size: 3rem; }
  .email-holder__hidden {
    display: none; }
  @media only screen and (max-height: 37.5rem) {
    .email-holder {
      height: 70vh; } }
  @media only screen and (max-width: 37.5rem) {
    .email-holder {
      height: 70vh; } }
  @media only screen and (max-device-width: 600px) {
    .email-holder {
      height: 70vh; } }

#projects-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  @media only screen and (max-width: 75em) {
    #projects-link-container {
      margin-top: 0; } }
  @media only screen and (max-height: 37.5rem) {
    #projects-link-container {
      text-align: center;
      font-size: 2rem; } }
  @media only screen and (max-width: 37.5rem) {
    #projects-link-container {
      text-align: center;
      font-size: 2rem; } }
  @media only screen and (max-device-width: 600px) {
    #projects-link-container {
      text-align: center;
      font-size: 2rem; } }
  #projects-link-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem; }
    #projects-link-container div span {
      font-size: 1.25rem; }
      @media only screen and (max-height: 37.5rem) {
        #projects-link-container div span {
          font-size: 1.25rem; } }
      @media only screen and (max-width: 37.5rem) {
        #projects-link-container div span {
          font-size: 1.25rem; } }
      @media only screen and (max-device-width: 600px) {
        #projects-link-container div span {
          font-size: 1.25rem; } }
  #projects-link-container a {
    font-size: 2rem;
    cursor: pointer;
    margin-bottom: 5px;
    display: inline-block;
    z-index: 2000; }
    @media only screen and (max-width: 75em) {
      #projects-link-container a {
        font-size: 1.75rem; } }
    @media only screen and (max-height: 37.5rem) {
      #projects-link-container a {
        font-size: 2rem; } }
    @media only screen and (max-width: 37.5rem) {
      #projects-link-container a {
        font-size: 2rem; } }
    @media only screen and (max-device-width: 600px) {
      #projects-link-container a {
        font-size: 2rem; } }
  #projects-link-container a:hover {
    transition: 0.2s all ease-out;
    transform: scale(1.1);
    text-shadow: 0 0 20px; }

#free-time .card__main {
  display: flex;
  align-items: center; }
  @media only screen and (max-height: 37.5rem) {
    #free-time .card__main {
      margin: 0;
      justify-content: center;
      height: 100%; } }
  @media only screen and (max-width: 37.5rem) {
    #free-time .card__main {
      margin: 0;
      justify-content: center;
      height: 100%; } }
  @media only screen and (max-device-width: 600px) {
    #free-time .card__main {
      margin: 0;
      justify-content: center;
      height: 100%; } }

#free-time .email-holder {
  margin-top: 6rem; }

#free-time:hover .card__icon {
  opacity: 0; }
  @media only screen and (max-width: 75em) {
    #free-time:hover .card__icon {
      opacity: 1; } }

.duck-image {
  width: 350px;
  height: 350px;
  fill: url(#yellow-dark-gradient); }
  @media only screen and (max-width: 75em) {
    .duck-image {
      fill: rgba(184, 134, 11, 0.8);
      animation: 3s animateStroke ease-in-out infinite alternate;
      stroke-width: 1px;
      transform: scale(0.8); } }
